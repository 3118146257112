:root {
  --main-bg-color: #E1B07E;
  --second-bg-color: #E5BE9E;
  --thirth-bg-color: #86A397;
  --fourth-bg-color: #CBC0AD;
}

html, body, #reactapp, .App {
  background-image: url('images/background-full4.png');
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-position: top;
  background-position-x: center;
  background-origin: content-box;
  background-size: cover;
  background-attachment: fixed;
}

.App {
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar-brand img {
  margin-right: 15px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-container {
  display: flex;
  align-items: center;
  min-height: 100%;
}

.container {
  background-color: rgba(240, 239, 239, 0.95);
  
  min-width: 500px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0px;
  border-radius: 10px;
}

.title {
  text-align: center;
  margin: 0px;
  padding: 10px 0px 10px 0px;
  height: 50px;
}

.page {
  width: 100%;
  margin: 0px;
  padding: 0px 20px 0px 20px;
}

H1 {
  font-size: x-large;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
}

.navwrapper {
  margin: 0px;
  padding: 0px;
  border-radius: 10px 10px 0px 0px;
  height: 150px;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%);
}

.navbar {
  border-radius: 20px 20px 0px 0px;
  box-shadow: none;
  z-index: 9999;
}
.nav-item {
  margin-left: 30px;
  font-weight: bold;
}

.nav-link {
  color: gray;
}

.content {
  margin: 10px;
  background-color: var(--thirth-bg-color-bg-color);
  
}

.pageWrapper {
  min-height: 520px;
}

.meekijken {
  margin: 10px;
  opacity: 95%;
  border-color: #361D2E;
  border-style: none;
  border-width: 1px;
  border-radius: 5px;;
  background-color: var(--fourth-bg-color);
  padding: 10px;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%);
}

.agenda {
  margin: 10px;
  opacity: 95%;
  border-color: #361D2E;
  border-style: none;
  border-width: 1px;
  border-radius: 5px;;
  background-color: var(--thirth-bg-color);
  padding: 10px;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 5%);
  min-width: 250px;
}

.kerktijdenWidget {
  padding-left: 0.5rem;
}

.kerktijdenItem {
  border-color: #361D2E;
  border-width: 2px;
  border-style: none;
  margin-bottom: 10px;
}


.meeluisteren {
  padding-left: 0.5rem;
}

.userInfo {
  font-size: 14px;
}

.userInfo .nav-link {
  padding: 0px;
}

.footer {
  background-color: rgba(251, 251, 251, 1) !important;
  border-radius: 0 0 10px 10px;
  padding-bottom: 10px;
  margin-top:20px;
  padding-left:10px;
}

.NotFound {
  
}

.txt-normal {
  font-size: medium;
}

.txt-small {
  font-size: small;
}

.txt-x-small {
  font-size: x-small;
}

.center {
  text-align: center;
}

h3 {
  color: #361D2E;
}

.loading {
  width: 100%;
  height: 200px;
  vertical-align: middle;
  text-align: center;
  padding-top: 50%;
}

.kdgm-responsive-player > iframe {
  max-width: 100%;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.wp-block-group__inner-container {
  margin-bottom: 75px;
}

.eventsLegendWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid;
  margin-top: 10px;
  text-align: center;
}


.eventsHeader {
  width: 100%;
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid;
  text-align-last: justify;
  min-width: 160px;
  display: flex;
}

.eventSelectors {
  width: 50%;
}

.eventSettings {
  width: 50%; 
  text-align-last: right;
}

.eventsPrint {
  width: 10%; 
  text-align-last: right;
  display: inline-flex;
  justify-content: flex-end;
}

.printButton {
  border: none;
  color: none;
}

.EventsPrintView {
  background-image: none;  
  @page { size: landscape; }
}

.eventsMonthNavigation {
  width: 35%;
  min-width: 50px;
  max-width: 150px;
  display: inline-flex;
}

.eventsMonthNavigationNext {
  text-align: right;
}
.eventsCurrentMonth{
  width: 30%;
  min-width: 50px;
  max-width: 150px;
  display: inline-flex;
  text-align: justify;
}

.eventsNumberOfMonths {
  width: 80%;
  display: inline-flex;
  justify-content: flex-end;
}



.eventsNumberOfMonths select{
  margin-left: 10px;
  margin-right: 10px;
}

.ledenAddButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}


.ledenFilter {
  width: 100%;
  text-align: justify;
  align-items: center;  
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.ledenFilter button {
  border: none;
  background-color: inherit;
  padding-left: 2px;
  padding-right: 2px;
  overflow: visible;
  min-width: 1px;
}

.leden {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ledenCard {
  width: 300px;
  border: 1px solid;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}
.ledenCardDetails{
  width: 80%;
  display: inline-block;
}
.ledenCardDetailsEdit {
  width: 20%;
  display: inline-block;
}

.noResult {
  width: 100%;
  border-radius: 2px;
  margin: 10px;
  padding: 10px;
}

.errorConsole {
  height: 100px;
  width: 100%;
  background-color: black;
  color: red;
  display: block;
  margin: 10px;
  border-radius: 5px;
}

.wp-block-file__embed {
  display: block !important;
}